<template>
  <svg
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2095.347 849.333"
  >
    <g fill="currentColor">
      <path
        d="M432 204H230.667c-11.046 0-20-8.955-20-20V20c0-11.045-8.955-20-20-20H20C8.954 0 0 8.955 0 20v605.333c0 11.046 8.954 20 20 20h170.667c11.045 0 20-8.954 20-20v-268c0-13.99 11.342-25.333 25.333-25.333h13.333c13.991 0 25.334 11.343 25.334 25.333v268c0 11.046 8.954 20 20 20h170.666c11.046 0 20-8.954 20-20v-368C485.333 227.88 461.455 204 432 204M1538.667 204H1368c-11.04 0-20 8.955-20 20v268c0 13.99-11.347 25.333-25.333 25.333h-13.334c-13.99 0-25.333-11.342-25.333-25.333V224c0-11.045-8.955-20-20-20h-170.667c-11.045 0-20 8.955-20 20v368c0 29.455 23.879 53.333 53.334 53.333H1328c11.04 0 20 8.955 20 20v164c0 11.046 8.96 20 20 20h170.667c11.04 0 20-8.954 20-20V224c0-11.045-8.96-20-20-20M2075.347 204H1904.68c-11.04 0-20 8.955-20 20v268c0 13.99-11.347 25.333-25.333 25.333h-13.334c-13.986 0-25.333-11.342-25.333-25.333V224c0-11.045-8.947-20-20-20h-170.667c-11.04 0-20 8.955-20 20v368c0 29.455 23.88 53.333 53.334 53.333h378.666c29.454 0 53.334-23.878 53.334-53.333V224c0-11.045-8.947-20-20-20M735.887 245.787l-2.315-13.131c-2.175-12.328 6.057-24.084 18.385-26.259l44.646-7.872c12.328-2.173 24.084 6.059 26.258 18.387l2.315 13.13c2.173 12.329-6.057 24.085-18.387 26.26l-44.644 7.871c-12.328 2.174-24.084-6.058-26.258-18.386zm292.13 155.636c-1.917-10.878-12.29-18.142-23.169-16.223l-214.03 37.74c-12.329 2.173-24.086-6.059-26.26-18.387l-2.315-13.13c-2.174-12.328 6.058-24.086 18.386-26.259l181.206-31.95c29.006-5.117 48.376-32.778 43.261-61.786l-24.079-136.555c-6.01-34.086-38.514-56.846-72.602-50.837L559.14 145.623c-29.006 5.114-48.376 32.777-43.261 61.784l58.115 329.582c5.114 29.008 32.776 48.378 61.784 43.263l391.297-68.997c10.877-1.918 18.141-12.291 16.223-23.168l-15.282-86.664"
      />
    </g>
  </svg>
</template>
